<template>
  <div class="colorBox">
    <div class="content">
      <el-table
        ref="tableList"
        :data="tableData"
        style="width: 100%; margin: 15px 0"
        border
        :max-height="800"
        :header-cell-style="{background:'#DCDFE8',color:'#5C6B91'}"
      >
        <el-table-column label="序号" type="index" align="center" width="50"> </el-table-column>
        <el-table-column label="巡检区域" align="center" prop="name"> </el-table-column>
        <el-table-column
          prop="value"
          label="显示颜色"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <el-color-picker v-model="row.value" @change="selectColor(row)"></el-color-picker>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="left" fixed="right" width="300">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.generalStatus == 1"
              plain
              type="text"
              style="color:#FF5400"
              @click="submit(row,0)"
              >停用</el-button
            >
            <el-button
              v-if="row.generalStatus == 0"
              plain
              type="text"
              @click="submit(row,1)"
              >启用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content">
      <el-table
        ref="tableList"
        :data="sdrprList"
        style="width: 100%; margin: 15px 0"
        border
        :max-height="800"
         :header-cell-style="{background:'#DCDFE8',color:'#5C6B91'}"
      >
        <el-table-column label="序号" type="index" align="center" width="50"> </el-table-column>
        <el-table-column label="巡检区域" align="center" prop="name"> </el-table-column>
        <el-table-column
          prop="value"
          label="显示颜色"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <el-color-picker v-model="row.value"  @change="selectColor(row)"></el-color-picker>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="left" fixed="right" width="300">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.generalStatus == 1"
              plain
              type="text"
              style="color:#FF5400"
              @click="submit(row,0)"
              >停用</el-button
            >
            <el-button
              v-if="row.generalStatus == 0"
              plain
              type="text"
              @click="submit(row,1)"
              >启用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { patrolGetDict ,patrolEditDict } from "../../RequestPort/maintenance";
// import { getPlansGroupPage, deletePlansGroup } from '@/apis/emergencyPlan'
export default {
  name: 'Plangroup',
  data() {
    return {
      // 默认颜色
      color1: '#409EFF',
      searchForm: {},
      pageParams: {
        current: 1,
        size: 100
      },
      // 钢管列表
      tableData: [],
      // pe管列表
      sdrprList:[],
      total: null,
     
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * 选择颜色触发
     */
    selectColor(val){
      let data ={
        name: val.name,
        id: val.id,
        pid: val.parentId,
        value: val.value
       }
      patrolEditDict(data).then(res => {
        if(res.code == 200) {
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 提交
     */
    submit(val,index){
      let data ={
        name: val.name,
        id: val.id,
        pid: val.parentId,
        value: val.value,
        generalStatus:index
       }
      patrolEditDict(data).then(res => {
        if(res.code == 200) {
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 获取列表
     */
    getList() {
      patrolGetDict({pid:'524,518,70,46',dictType:'line'}).then(res => {
        if(res.code == 200) {
          res.data[0].children[0].children.forEach(el => {
            // pe管
            if(el.id == 46) {
              this.sdrprList = el.children
            }
            // 钢管 
            else if(el.id == 70) {
              this.tableData = el.children
            }
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.btn {
  color: #3f74d5;
  border: 1px solid #3f74d5;
}
.warning-row {
  background: #0fffff!important;
}
// 盒子
.colorBox{
  display: flex;
  flex-direction: row;
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px 113px;
  .content{
    width: 692px;
    margin: 34px;
  }
}
</style>
